.subContainer_navbar{
    display: flex;
    justify-content: space-between;
 padding-right: 200px;
}

.logoImage{
    width: 110px;
}

.rightside{
    display: flex;
    gap: 30px;
}

.textRightSide{
    text-decoration: none;
    color: black;
}

.textRightSide:hover{
    cursor: pointer;
    color: grey;
}


@media only screen and (max-width: 600px) {

    .subContainer_navbar{
        flex-direction: column;
        justify-content:center;
        align-items: center;
        padding: 0;
    }
    
    .logoImage{
        width: 200px;
    }

    .rightside{
        display: flex;
        gap: 30px;
        font-size: large;
    }
}