.maincontainer_searchpage{
    margin-top: 20px;
    padding: 5px 100px;
    min-height: 80vh;
}

.headContainer_form_searchpage{
    width: 100%;
    display: flex;
    column-gap: 5px;
    align-items: center;
    border-bottom: 1px solid black;
    
}

.button_SearchPage{
    position: relative;
    top: 17px;
    border-radius: 7px;
    border: none;
}

.button_SearchPage:hover{
    background-color: rgb(197, 197, 197);
}

.details_searchpage_aftername{
    margin-top: 20px;
}

.subContainer_details_searchpage_aftername{
    display: flex;
    font-size: large;
    column-gap: 200px;
    padding-left: 0;
    font-family: Arial, Helvetica, sans-serif;
}

.alert{
    color: grey;
    font-size: medium;
}




@media only screen and (max-width: 600px){
    .maincontainer_searchpage{
        width: 100%;
        padding: 0;
        margin: 0;
        font-size: larger;
    }

    .maincontainer_searchpage h5{
        text-align: center;
        margin-top: 30px;
    }

    .inputBox_searchpage{
        padding: 10px;
        margin: 0;
        width: 70vw;
        font-size:x-large;
    }

    .button_SearchPage{
        width: 15vw;
        height: 15vw;
        background-color: rgb(29, 29, 29);
        color: white;
        top: 18px;
        right: 5px;
    }

    .subContainer_details_searchpage_aftername{
        flex-direction: column;
    font-size: 18px;
    }

    .details_searchpage_aftername h5{
        text-align: left;
    }

    .hrLine{
        width: 100%;
        color: black;
    }

    .table{
        font-size:medium;
        text-align: center;
        border-bottom: 1px solid rgb(166, 166, 166);
    }


}