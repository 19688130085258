.subContainer_Body{
    display: flex;
    justify-content: space-between;
    padding: 0px 150px;
    align-items: center;
}

.subContainer_Body p{
    width: 22rem;
font-size: medium;

}

.subContainer_Body img{
    width: 35rem;
}

.youtubeIconBody{
    color: rgb(255, 0, 0);
    
}

.youtubeIconBody:hover{
    color: rgb(241, 113, 113);
}

.paragraphAndLink{
    display: flex;
    flex-direction: column;   
}



@media only screen and (max-width: 600px){
    .subContainer_Body{
        margin-top: 100px;
        flex-direction: column;
        padding: 0;
    }

    .subContainer_Body p{
        width: 25rem;
        font-size: large;
    }

    .subContainer_Body img{
        width: 30rem;
        position: relative;
        bottom: 130px;
        z-index: -9999;
    }
}