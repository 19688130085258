.MainCOntainer_footer{
    display: flex;
    justify-content: space-between;
    margin-top: auto;
}


@media only screen and (max-width: 600px){

    .MainCOntainer_footer{
        top: 100px;
        flex-direction: column;
    }

}